<template>
  <div class="customer__main__page">
    <div class="customer__main__page__header">
      <div class="customer__main__page__header__container">
        <div class="customer__main__page__header__title">Список тендеров</div>
        <div class="customer__main__page__header__input">
          <a-select
              placeholder="Поиск"
              size="large"
              @change="choseSubtags"
              showArrow
              mode="multiple"
              class="customer__main__page__select tags"
              :maxTagCount="2"
              :maxTagTextLength="17"
              v-model:value="queryTags"
          >
            <a-select-option v-for="i in getWorkTagsValue()" :key="i.id" >
              {{ i.name }}
            </a-select-option>
          </a-select>

          <a-select
              show-search
              size="large"
              class="customer__main__page__select"
              placeholder="Москва,Россия"
              v-model:value="queryCity"
          >
            <a-select-option v-for="i in getCities()" :key="i.name"  @click="choseCity(i.name)">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

    <TabViewContainer :class="!this.$store.getters.getAllLoaded ? 'center' : ''">
      <Loader v-if="!this.$store.getters.getAllLoaded"/>

      <div class="cell_wrapper" v-if="this.$store.getters.getAllLoaded">
        <CustomerTenderCell
          v-for="tenderEl in this.$store.getters.getAllTenders"
          :key="tenderEl.id"
          :tender="tenderEl"
          class="announcement-doing-cell"
        ></CustomerTenderCell>
      </div>

      <div v-if="this.$store.getters.getAllLoaded" class="pagination">
        <a-pagination
          :current="currentPageAllTenders"
          :total="this.$store.getters.getAllTotalTenders"
          :showLessItems="true"
          show-less-items
          :defaultPageSize="6"
          :hideOnSinglePage="true"
          @change="onChangePagination"
        />
      </div>
    </TabViewContainer>
  </div>
</template>

<script>
import CustomerTenderCell from '@/components/CustomerComponents/CustomerTenderCell.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import Loader from '@/components/common/Loader.vue'

export default {
  async beforeMount() {
    await this.$store.dispatch('setSubTags')
    await this.$store.dispatch('setTags')
    await this.$store.dispatch('setCities', true)
  },
  mounted () {
    this.queryCity = this.$route.query.city
    this.queryTags = this.$route.query.tags ? [Number(this.$route.query.tags)] : []
    this.$store.dispatch('setAllTenders', {
      array: this.$store.getters.getAllTenders,
      page: this.currentPageAllTenders,
      tags: this.queryTags,
      address: this.queryCity
    })
  },
  components: {
    CustomerTenderCell,
    TabViewContainer,
    EmptyVue,
    Loader
    // Button,
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        }
      ],
      currentPageAllTenders: 1,
      tags: [],
      queryCity: '',
      queryTags: []
    }
  },
  methods: {
    onChangePagination (current) {
      this.currentPageAllTenders = current
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address: this.queryCity
      })
    },
    getWorkTagsValue () {
      let arr = this.$store.getters.getSubTags;
      return arr
    },
    getCities () {
      return this.$store.getters.getCities
    },
    choseCity (id) {
      this.currentPageAllTenders = 1
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address: id
      })
    },
    choseSubtags (tags) {
      this.currentPageAllTenders = 1
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders,
        tags: this.queryTags,
        address: this.queryCity
      })
    }
  }
}
</script>

<style lang="scss">
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  background-color: #f12a2a;
  color: white;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  fill: white;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  background-color: white;
  color: black;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 12px;
}

.ant-select-selection__choice {
  background-color: #b12021;
  color: white;
}
.customer__main__page {
  background-color: white;
  width: 100%;
  height: 100%;
  &__select {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    z-index: 9;
    &.tags{
      min-width: 500px;
      max-width: 500px;
    }
    &__option {
      background-color: #b12021;
      color: white;
    }
  }

  &__header {
    padding-right: 15%;
    padding-left: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__input {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: flex-end;
      &-search {
        width: 400px;
      }
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

@media (max-width: 992px) {
  .customer__main__page {
    &__header {
      padding-right: 10%;
    }
  }
}

@media (max-width: 768px) {
  .customer__main__page {
    &__header {
      &__input {
        &-search {
          width: 100%;
        }
      }
    }
  }
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 20px;
  box-shadow: 0px 1px 12px -4px rgba(34, 60, 80, 0.2);
  background-color: white;
}

.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}

@media (max-width: 768px) {
  .customer__main__page {
    &__select {
      width: 100%;
      &.tags{
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .customer__main__page {
    &__header {
      padding-right: 5%;
    }
    &__select {
      width: 100%;
      max-width: 100%;
    }
  }
}

.cell_wrapper{
  width: 100%;
}

.center{
  justify-content: center;
}
</style>
